import { Breadcrumbs, Link } from '@mui/joy';
import { useState } from 'react';
import Search from './search';
import EditPortfolio from './edit';

function Portfolio() {

    const PageTypes = {
        Search : 0,
        Edit : 1
    } 

    const [pageType, setPageType] = useState(PageTypes.Search);

    const [portfolio, setPorfolio] = useState();

    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/portfolio/search">
                         Portfolios
                    </Link>
                    { portfolio && (
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/portfolio/edit/"
                        >
                        Edit Portfolio
                    </Link>
                        )
                    }
                </Breadcrumbs>
            </div>
            <div>
                { portfolio ? 
                    (<EditPortfolio portfolio={portfolio} />)
                    :
                    (<Search onPortfolioSelected={setPorfolio} />)

                }
            </div>
        </div>
    );
};

export default Portfolio;