import Stock from "../../../models/stock";
import { Portfolio } from "../../../models/portfolio";

class IPortfolioAPI {
    constructor(tag) {
      this.tag = tag;
    }

    async getPortfolio() {
        return {};
    }
  
    // Returns stock model
    async isStockInPortfolio(stock, portfolio) {
        return true;
    }

    async addStockToPortfolio(stock, portfolio) {
        return true;
    }

    async removeStockToPortfolio(stock, portfolio) {
        return true;
    }

    async getPortfolioDetails(portfolio) {
        return [];
    }
  }

  export default IPortfolioAPI;