import IStockAPI from "../IStockAPI";
import Stock from "../../../models/stock";
import StockDetails from "../../../models/stockdetails";

//const ALPHA_VANTAGE_API_KEY = "demo"; //demo
//const ALPHA_VANTAGE_API_KEY = "LKRREGP2RXUYGGBJ"; //test
const ALPHA_VANTAGE_API_KEY = "D220ODI4U8I6ISOQ";  //prod 

const BASE_URL = "https://www.alphavantage.co";

class AlphaVantageAPI extends IStockAPI {

    async getStockBySearchTerm(searchTerm) {
        const searchUrl = `${BASE_URL}/query?function=SYMBOL_SEARCH&keywords=${searchTerm}&apikey=${ALPHA_VANTAGE_API_KEY}`
        let response = await fetch(searchUrl);
        let responseJson = await response.json();
        console.log("Respnsejson: ");
        console.log(responseJson);
        let stockResponses = responseJson.bestMatches.map((stock) => new Stock(stock["2. name"], stock["1. symbol"]));
        return stockResponses;
    }

    async getStockDetails(stock) {
        const companyInfoUrl  = `${BASE_URL}/query?function=OVERVIEW&symbol=${stock.symbol}&apikey=${ALPHA_VANTAGE_API_KEY}`;
        let response = await fetch(companyInfoUrl);
        let responseJson = await response.json();

        const ppsUrl = `${BASE_URL}/query?function=TIME_SERIES_MONTHLY&symbol=${stock.symbol}&apikey=${ALPHA_VANTAGE_API_KEY}`;
        let ppsResp = await fetch(ppsUrl);
        let ppsJson = await ppsResp.json();

        let ppsEntries = Object.entries(ppsJson["Monthly Time Series"]);

        console.log(ppsJson);

        console.log(Object.entries(ppsJson["Monthly Time Series"])[0][1]["4. close"]);

        let currentPPs = ppsEntries[0][1]["4. close"];

        let pps1yrAgo = ppsEntries[11][1]["4. close"];


        let detailsResponse = new StockDetails(stock, 
                                               responseJson.Sector, 
                                               responseJson.Industry,
                                               responseJson.MarketCapitalization,
                                               currentPPs,
                                               pps1yrAgo,
                                               this.calculatePercentageDifference(pps1yrAgo, currentPPs),
                                               "N/A"
                                               )
        return detailsResponse;
    }

    calculatePercentageDifference(oldValue, newValue) {
        // Convert string inputs to numbers (doubles)
        oldValue = parseFloat(oldValue);
        newValue = parseFloat(newValue);
    
        // Calculate the difference and percentage difference
        let difference = newValue - oldValue;
        let percentageDifference = (difference / Math.abs(oldValue)) * 100;
    
        return percentageDifference;
    }
}

export default AlphaVantageAPI;