import { useState } from "react";
import {Form, FormControl, Dropdown, FormLabel, Spinner, Stack} from "react-bootstrap";
import { searchStocks } from "../../services/StockService";

function StockSearch({ onStockSelected }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStock, setSelectedStock] = useState();


    const handleInputChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (selectedStock && selectedStock.name == searchQuery) {
            return;
        }

        setLoading(true);
    
        // Filter options based on the search query
        let stocks = await getStockSymbols(query);
    
        // Update dropdown options
        setDropdownOptions(stocks);

        setLoading(false);
    };

    const getStockSymbols = async (searchTerm) => {
        try {
            let stocks = await searchStocks(searchTerm);

            if (stocks.length > 10) {
                stocks = stocks.splice(0,10)
            }

            return stocks;
           } catch(error) {
            return [];
          }
    }
    
    const handleOptionSelect = (selectedStock) => {
        setSelectedStock(selectedStock);
        setSearchQuery(selectedStock.name);
        setDropdownOptions([]);
        setLoading(false);
        onStockSelected(selectedStock);
    // You can perform any other actions based on the selected option if needed
    };

    return (
        <Form>
            <FormLabel className="title-text">ADD A STOCK</FormLabel>
            <FormControl
                type="text"
                placeholder="Stock symbol or company name...."
                className="stock-search-input"
                value={searchQuery}
                onChange={handleInputChange}
            />
            <Dropdown show={searchQuery.length > 0 && dropdownOptions.length > 0}>
            <Dropdown.Menu>
                {dropdownOptions.map((stock, index) => (
                <Dropdown.Item
                    key={index}
                    onClick={() => handleOptionSelect(stock)}
                >
                    <Stack className="d-flex justify-content-between align-items-start">
                        <div className="stock-search-symbol">
                            {stock.symbol}
                        </div>
                        <div className="stock-search-name">
                            {stock.name}
                        </div>
                    </Stack>
                </Dropdown.Item>
                ))}
            </Dropdown.Menu>
            </Dropdown>
        </Form>
    )
}

export default StockSearch;