
class Stock {
    constructor(name, symbol, id) {
      this.id = id;
      this.name = name;
      this.symbol = symbol
      this.details = null;
    }

    getId() {
      return this.id;
    }
  
    getName() {
        return this.name;
    }

    getSymbol() {
        return this.symbol;
    }

    getDetails() {
      return this.details;
    }

    setDetails(details) {
      this.details = details;
    }
  }

  export default Stock;