import ParsePortfolioAPI from "./ParsePortfolioAPI"

const portfolioAPI = new ParsePortfolioAPI("parse portfolio");

const isStockActive = async (stock, portfolio) => {
    return portfolioAPI.isStockInPortfolio(stock, portfolio);
}

const addStockToPortfolio = async (stock, portfolio) => {
    return portfolioAPI.addStockToPortfolio(stock, portfolio);
} 

const removeStockToPortfolio = async (stock, portfolio) => {
    return await portfolioAPI.removeStockToPortfolio(stock, portfolio);
} 

const getCurrentPortfolio = async () => {
    return await portfolioAPI.getPortfolio();
} 

const getPortfolioDetails = async (portfolio) => {
    return await portfolioAPI.getPortfolioDetails(portfolio);
}

export {
    isStockActive,
    addStockToPortfolio,
    removeStockToPortfolio,
    getCurrentPortfolio,
    getPortfolioDetails
};