import Stock from "../../../models/stock";

class IStockAPI {
    constructor(tag) {
      this.tag = tag;
    }
  
    // Returns stock model
    async getStockBySearchTerm(searchTerm) {
        return [];
    }

    async getStockDetails(stock) {
        return {};
    }
  }

  export default IStockAPI;