import stock_logo from '../stockilator.jpg'
import '../App.css';
import Login from '../login';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function MainPage() {

    const onLoginSuccessfull = () => {
        console.log("login successful");
    };

    const onLoginFailed = () => {
        console.log("login unsuccessful");
    };

    return (
        <div>
            {/* <div style={{height:'10%'}}>
                <img src={stock_logo}  className="App-logo" alt="logo" />

            </div>
            <p>
                    A NEW VERSION COMING SOON! 
                </p> */}
            <div style={{height:'auto'}}>
                <Login onLoginPass={onLoginSuccessfull} onLoginFail={onLoginFailed} />
            </div>
        </div>
    );
  }
  
  export default MainPage;