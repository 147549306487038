import { useState } from "react";
import { Stack, Container } from "react-bootstrap";
import StockDetailsPage from "./stock-details";
import StockSearch from "./stock-search";
import Stock from "../models/stock";
import Parse from "parse";

function Lookup() {
    const [stock, setStock] = useState(null);

    function onStockSelected(selectedStock) {
        setStock(selectedStock);
    }

    async function addStockToPortfolio(stock) {
        const parseStock = await findStock(stock);
        const portfolio = await findPortfolioForUser();
  
        let port = portfolio.relation('stocks');
        port.add(parseStock);
  
        await portfolio.save();
    }

    async function removeStockFromPortfolio(stock) {
        const parseStock = await findStock(stock);
        const portfolio = await findPortfolioForUser();
  
        let port = portfolio.relation('stocks');
        port.remove(parseStock);
  
        await portfolio.save();
    }

    async function findStock(stock) {
        let query = new Parse.Query("Stock");
        query.equalTo("symbol", stock.getSymbol());

        let foundStock = await query.first();

        if (foundStock == null ) {
            foundStock = new Parse.Object("Stock");
            foundStock.set("name", stock.getName());
            foundStock.set("symbol", stock.getSymbol());
            await foundStock.save();
          }

        return foundStock;
    }

    async function findPortfolioForUser() {
        let user = await Parse.User.current();

        const portfolioPointer = user.get('portfolio');

        let portfolio = null;

        if (portfolioPointer != null) {
            portfolio = await portfolioPointer.fetch();
        }

        if(portfolio == null) {
            portfolio = new Parse.Object('Portfolio');
            portfolio.set('name', `${user.get('username')} portfolio`);

            await portfolio.save();

            user.set('portfolio', portfolio);

            await user.save();

        }

        return portfolio;

    }

    function onSearchAgain() {
        setStock(null);
    }

    return (
        <Container fill>
            {stock ?
                (
                    <StockDetailsPage stock={stock} onSearchAgain={onSearchAgain}/>
                ) 
                :
                (
                    <StockSearch onStockSelected={onStockSelected} />
                )
            }
        </Container>
    )
}

export default Lookup;