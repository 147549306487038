import './style.css';
import { Container, Row, Col } from 'react-bootstrap';
import { getCurrentPortfolio, getPortfolioDetails } from '../services/PortfolioService';
import { useEffect, useState } from 'react';
import PerformanceDetailsPage from './performance-details';
import { render } from '@testing-library/react';
import React from 'react';


class PerformancePage extends React.Component {

    constructor(props) {    
        super(props);

        this.state = {
            stockSections : [],
            selectedStock : null,
            sections: [
                { sectionClassName: 'colorSection icarian', title: 'ICARIAN', subtitle: 'HIGH RISK' },
                { sectionClassName: 'colorSection growth', title: 'GROWTH', subtitle: 'LOW RISK' },
                { sectionClassName: 'colorSection flatliner', title: 'FLATLINER', subtitle: 'RISKY' },
                { sectionClassName: 'colorSection correction', title: 'CORRECTION', subtitle: 'HIGH RISKY' },
                { sectionClassName: 'colorSection abysmal', title: 'ABYSMAL', subtitle: 'VERY HIGH RISK' },
            ]
        };
    }

    getStockStatusColor(stock) {
        if (stock.getDetails().getPerformance() > 100) {
            return 'column-row icarian';
        }
        else if (stock.getDetails().getPerformance() > 50) {
            return 'column-row growth';
        }
        else if (stock.getDetails().getPerformance() > 25) {
            return 'column-row flatliner';
        }
        else if (stock.getDetails().getPerformance() > 10) {
            return 'column-row correction';
        }
        else {
            return 'column-row abysmal';
        }
    }

    async reloadStockSections() {
        let portfolio = await getCurrentPortfolio();

        let stockDetails = await getPortfolioDetails(portfolio);

        let newStockSections = [];

        // group stock details by items of 3, account for item out of index
        for (let i = 0; i < stockDetails.length; i += 3) {
            let section = stockDetails.slice(i, i + 3);
            newStockSections.push(section);
        }

        this.setState({stockSections: newStockSections})
    }

// useEffect(() => {
//     //reloadStockSections();
// }, []);

    componentDidMount() {
        console.log("componentDidMount");
        this.reloadStockSections();
    }

    

    render() {
        return (
                (this.state.selectedStock == null) ? (
                    <div className='fullbody' style={{ width: '100%', padding: '20px', height: '100%' }}>
                        <div className="title-text header">Performance</div>
                        <div className="bar" >
                            {this.state.sections.map((section) => (
                                <div className='section'>
                                    <div className='title'>{section.title}</div>
                                    <div className={section.sectionClassName}></div>
                                    <div className='subTitle'>{section.subtitle}</div>
                                </div>
                            )
                            )}
                        </div>
                        <div className='stocklayout' style={{ paddingTop:'20px' }}>
                            <Container style={{ height: '100%' }}>
                                {this.state.stockSections.map((section) => (
                                    <Row>
                                        {section.map((stock) => (
                                            <Col>
                                                <div className={this.getStockStatusColor(stock)} onClick={() => this.setState({selectedStockntity: stock})} >
                                                    <div className='stockPrice'>{stock.getDetails().getPPS()}</div>
                                                    <div className='stockSymbol'>{stock.symbol}</div>
                                                    <div className='stockName'>{stock.name}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                            </Container>
                        </div>
                    </div>) :
                    <PerformanceDetailsPage stock={this.state.selectedStock} onBackSelected={() => this.setState({selectedStock : null})} />
            );
    }
}


export default PerformancePage;



