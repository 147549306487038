import { Card, Stack, Button, Container } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import { getStockDetails } from "../../services/StockService";
import { useEffect, useState } from "react";
import StockDetails from "../../models/stockdetails";
import { isStockActive, addStockToPortfolio, removeStockToPortfolio, getCurrentPortfolio} from "../../services/PortfolioService";


function PerformanceDetailsPage({ stock, onBackSelected }) {

    const [stockActive, setIsStockActive] = useState(false);
    const [portfolio, setPortfolio] = useState(null);
    const [stockdetails, setStockDetails] = useState(
        new StockDetails(null,
            "",
            "",
            "",
            "",
            "",
            "",
            "")
    );


    const fetchPortfolio = async() => {
        let port = await getCurrentPortfolio(stock);
        setPortfolio(port);
        await refreshStockStatus(port);
    }

    const refreshStockStatus = async (port) => {
        let stockAdded = await isStockActive(stock, port);
        setIsStockActive(stockAdded);
    }

    const goBack = async () => {
        onBackSelected();
    }
    
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        async function fetchData() {
            // You can await here
            console.log("getting details");
            let details = await getStockDetails(stock);
            console.log(details);
            setStockDetails(details);
            await fetchPortfolio();  
        };
        
        fetchData();      
    },[]);
     

    return (
        <Card style={{ width: '100%', height: '100%'}} className="description-card">
            <Card.Body style={{ paddingBottom: '10px', background: 'transparent'}}>
                <Card.Title className="title-text" >Key Statistics</Card.Title>
            </Card.Body>
            <ListGroup className="flush" style={{ background: 'transparent', borderColor: 'transparent'}}>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">SYMBOL</div>
                    <div className="text-right">{stockdetails.getSymbol()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">COMPANY NAME</div>
                    <div className="text-right">{stockdetails.getName()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">SECTOR</div>
                    <div className="text-right">{stockdetails.getSector()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">INDUSTRY</div>
                    <div className="text-right">{stockdetails.getIndustry()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">MARKET CAP</div>
                    <div className="text-right">{stockdetails.getMarketCap()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">CURRENT PPS</div>
                    <div className="text-right">{stockdetails.getPPS()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">PPS 1 YEAR AGO</div>
                    <div className="text-right">{stockdetails.getPPS1YAgo()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">PERFORMANCE</div>
                    <div className="text-right">{stockdetails.getPerformance()}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start description-item">
                    <div className="text-left">STOCKILATOR RANKING</div>
                    <div className="text-right">{stockdetails.getStokilatorRanking()}</div>
                </ListGroup.Item>
            </ListGroup>
            <Card.Body gap={3} style={{ paddingTop: '10px' }}>
                <Card.Link variant="primary" onClick={goBack}>Go Back</Card.Link>
            </Card.Body>
        </Card>
    )
}

export default PerformanceDetailsPage;