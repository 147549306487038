import MainPage from './main';
import { useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';
import Dashboard from './dashboard';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const user = useSelector((state) => state.user);


  return (
    <div className="App">
      <MetaTags>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </MetaTags>
      { user ? (
        <Dashboard />
      ) : (
        <MainPage />
      )}
    </div>
  );
}

export default App;
