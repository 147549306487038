import IPortfolioAPI from "../IPortfolioAPI";
import Parse from "parse";
import Portfolio from "../../../models/portfolio";
import { getStockDetails } from "../../StockService";
import Stock from "../../../models/stock";

class ParsePortfolioAPI extends IPortfolioAPI {

    async getPortfolioDetails(portfolio) {
        const parsePortfolio = await new Parse.Query('Portfolio').get(portfolio.id)
        let stockRelation = parsePortfolio.relation('stocks');
        let query = stockRelation.query();

        let stocks = await query.find();
        let stockModels = [];


        for (let stock of stocks) {
            let newStock = new Stock(stock.get("name"), stock.get("symbol"));
            let stockDetails = await getStockDetails(newStock);
            newStock.setDetails(stockDetails);
            stockModels.push(newStock);
        }

        return stockModels;
    }
   
    async getPortfolio() {
        let user = await Parse.User.current();
        let portfolioPointer = user.get("portfolio");

        let parsePortfolio = await portfolioPointer.fetch();

        return new Portfolio(parsePortfolio.get("name"), parsePortfolio.id);
    }

    // Returns stock model
    async isStockInPortfolio(stock, portfolio) {
        let parseStock = await this.getParseStock(stock);

        const parsePortfolio = await new Parse.Query('Portfolio').get(portfolio.id)
  
        let stockRelation = parsePortfolio.relation('stocks');
        let query = stockRelation.query();

        let stockResult = null;
        try {
            stockResult =  await query
                                        .get(parseStock.id);
        } catch (ex) {
            return false;
        }
        return stockResult != null;
    }

    async addStockToPortfolio(stock, portfolio) {
        let parseStock = await this.getParseStock(stock);

        let parsePortfolio = await new Parse.Query('Portfolio').get(portfolio.id);
        console.log(parsePortfolio.toJSON());
        let stockRelation = parsePortfolio
                                .relation('stocks');

        stockRelation.add(parseStock);

        await parsePortfolio.save();
        return true;
    }

    async removeStockToPortfolio(stock, portfolio) {
        let parseStock = await this.getParseStock(stock);

        const parsePortfolio = await new Parse.Query('Portfolio').get(portfolio.id);
        let stockRelation = parsePortfolio
                                .relation('stocks');

        stockRelation.remove(parseStock);

        await parsePortfolio.save();
        return true;
    }

    async getParseStock(stock) {
        let query = new Parse.Query("Stock");
        query.equalTo("symbol", stock.getSymbol());
  
        let foundStock = await query.first();
  
        
        if (foundStock == null ) {
          foundStock = new Parse.Object("Stock");
          foundStock.set("name", stock.getName());
          foundStock.set("symbol", stock.getSymbol());
          await foundStock.save();
        }

        return foundStock;
    }

}

export default ParsePortfolioAPI;