import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Parse from "parse";

function ProfilePage() {


    const [changingPassword, setChangingPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("")

    const populateProfile = async () => {
        var user = await Parse.User.current();

        setUsername(user.get("username"));
        setEmail(user.get("email"));
    }

    const changePassword = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        var user = await Parse.User.current();

        user.setPassword(form.newpassword.value);
        await user.save();

        alert("Profile Password Successfully Change");
    }

    const updateProfile = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        var user = await Parse.User.current();

        user.set("username", form.username.value);
        user.set("email", form.email.value);

        await user.save();
        setUsername(form.username.value);
        setEmail(form.email.value);

        alert("Profile Successfully Updated");
    }

    useEffect(() => {

        populateProfile();
    }, [])

    return (
        <div >
        { !changingPassword ? (
            <Form onSubmit={updateProfile}>
                <Form.Group className="mb-3">
                    <Form.Label>Profile Settings</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control required placeholder="Username" defaultValue={username} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control required placeholder="Email" defaultValue={email} />
                </Form.Group>

                <Form.Group>
                    <Button variant="primary" type="submit">
                        Update
                    </Button>

                    <Button variant="primary" onClick={() => {
                        setChangingPassword(true);
                    }}>
                        Change Password
                    </Button>
                </Form.Group>
            </Form>
        ) : (
            <Form onSubmit={changePassword}>
                <Form.Group className="mb-3">
                    <Form.Label>Profile Settings</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Change Password</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3" controlId="oldpassword">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control required type="password" placeholder="Old Password" defaultValue={oldPassword} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="newpassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control required type="password" placeholder="New Password" defaultValue={newPassword} />
                </Form.Group>

                <Form.Group>
                    <Button variant="primary" onClick={() => {
                        setChangingPassword(false);
                    }}>
                        Cancel
                    </Button>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form.Group>
            </Form>
        )}


     </div>

    );
}

export default ProfilePage;