import VontageAPI from "./AlphaVantageAPI";
import StockAnalysisAPI from "./StockAnalysisAPI";

const stockAPI2 = new VontageAPI("AlphaVantage");
const stockAPI = new StockAnalysisAPI("StockAnalysis");

const searchStocks = async (searchTerm) => {
    let foundStocks = await stockAPI2.getStockBySearchTerm(searchTerm);
    return foundStocks;
}

const getStockDetails = async (stock) => {
    let stockDetails = await stockAPI2.getStockDetails(stock);
    return stockDetails;
} 

export {
    searchStocks,
    getStockDetails
};