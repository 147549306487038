import '../App.css';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import LoginPage from '../login';
import MainPage from '../main';
import Portfolio from '../porftolio';
import { Container, Navbar, Nav, Row, Col, Tab, Stack, Figure, Offcanvas, NavDropdown, Form, Button } from 'react-bootstrap';
import Lookup from '../lookup';
import ProfilePage from '../profile';
import { useState } from 'react';
import PerformancePage from '../performance';

/*





*/
function Dashboard() {
    const [activeTab, setActiveTab] = useState("lookup");
    
    return (
        <>
        {[false].map((expand) => (
            <Container fill fluid className="vh-100 d-flex flex-column">
                <Row>
                <Navbar collapseOnSelect key={expand} expand={expand} className="nav-header justify-content-between">
                        <Navbar.Brand href="#">
                        <Figure>
                            <Figure.Image
                                width={40}
                                height={80}
                                alt="S"
                                src="logo.png"
                            />
                            </Figure>
                        </Navbar.Brand>
                        <Figure>
                            <Figure.Image
                                width={180}
                                height={280}
                                alt="Slogan"
                                src="stockilator_slogan.png"
                            />
                        </Figure>
                        {/* <Figure>
                            <Figure.Image
                                className="justify-content-end"
                                width={40}
                                height={80}
                                alt="Profile"
                                src="profile-circle.256x256.png"
                            />
                        </Figure> */}
                        <Navbar.Toggle bg="white" variant='light' aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Menu
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3" >
                                    <Nav.Item>
                                        <Nav.Link eventKey="lookup" onClick={() => setActiveTab("lookup")}>
                                        <Figure>
                                            <Figure.Image
                                                alt="Lookup"
                                                src="icon_lookup.png"
                                            />
                                            <Figure.Caption>
                                                Look Up
                                            </Figure.Caption>
                                        </Figure>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link disabled eventKey="second">
                                        <Figure.Image
                                                alt="Tracking"
                                                src="icon_tracking.png"
                                            />
                                            <Figure.Caption>
                                                Tracking
                                            </Figure.Caption>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="performance" onClick={() => setActiveTab("performance")}>
                                        <Figure.Image
                                                alt="Performance"
                                                src="icon_performance.png"
                                            />
                                            <Figure.Caption>
                                                Performance
                                            </Figure.Caption>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link disabled eventKey="second">
                                        <Figure.Image
                                                alt="Ranking"
                                                src="icon_ranking.png"
                                            />
                                            <Figure.Caption>
                                                Ranking
                                            </Figure.Caption>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link disabled eventKey="second">
                                        <Figure.Image
                                                alt="kopi Coin"
                                                src="icon_kopicoin.png"
                                            />
                                            <Figure.Caption>
                                                Kopi Coin
                                            </Figure.Caption>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="profile" onClick={() => setActiveTab("profile")}>
                                        <Figure.Image
                                                alt="profile"
                                                src="profile-circle.256x256.png"
                                                width={40}
                                                height={80}
                                            />
                                            <Figure.Caption>
                                                Profile
                                            </Figure.Caption>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>  
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                </Navbar>
                </Row>
                <Row className="h-100" >
                    <Col >
                        <Tab.Container fill activeKey={activeTab} className="vh-100 d-flex flex-column" id="left-tabs-example" defaultActiveKey="profile">
                            <Row className="h-100">
                            <Tab.Content >
                                    <Tab.Pane eventKey="lookup" >
                                        <Lookup />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="profile">
                                        <ProfilePage />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="performance" mountOnEnter={true} unmountOnExit={true}>
                                        <PerformancePage />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                                </Tab.Content>
                            </Row>
                            <Row>
                                {/* <Navbar className="nav-header justify-content-between">
                                    <Container fluid fill style={{ padding:'0' }}>
                                        <Nav  variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="lookup">
                                                <Figure>
                                                    <Figure.Image
                                                        alt="Lookup"
                                                        src="icon_lookup.png"
                                                    />
                                                    <Figure.Caption>
                                                        Look Up
                                                    </Figure.Caption>
                                                </Figure>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link disabled eventKey="second">
                                                <Figure.Image
                                                        alt="Tracking"
                                                        src="icon_tracking.png"
                                                    />
                                                    <Figure.Caption>
                                                        Tracking
                                                    </Figure.Caption>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link disabled eventKey="second">
                                                <Figure.Image
                                                        alt="Ranking"
                                                        src="icon_ranking.png"
                                                    />
                                                    <Figure.Caption>
                                                        Performance
                                                    </Figure.Caption>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link disabled eventKey="second">
                                                <Figure.Image
                                                        alt="Performance"
                                                        src="icon_performance.png"
                                                    />
                                                    <Figure.Caption>
                                                        Ranking
                                                    </Figure.Caption>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link disabled eventKey="second">
                                                <Figure.Image
                                                        alt="kopi Coin"
                                                        src="icon_kopicoin.png"
                                                    />
                                                    <Figure.Caption>
                                                        Kopi Coin
                                                    </Figure.Caption>
                                                </Nav.Link>
                                            </Nav.Item>
                                            </Nav>
                                        </Container>
                                </Navbar> */}
                            </Row>
                        </Tab.Container>
                </Col>
                </Row>
            </Container>
        ))}
        </>
    );
}


export default Dashboard;