import IStockAPI from "../IStockAPI";
import Stock from "../../../models/stock";
import StockDetails from "../../../models/stockdetails";
import axios, * as others from 'axios';

//const express = require('express');
//const axios = require('axios');
const cheerio = require('cheerio');

//const app = express();

const BASE_URL = "https://stockanalysis.com";

class StockAnalysisAPI extends IStockAPI {

    async getStockBySearchTerm(searchTerm) {
        const searchUrl = `${BASE_URL}/api/search?q=${searchTerm}`
        let response = await fetch(searchUrl);
        let responseJson = await response.json();
        console.log("Respnsejson: ");
        console.log(responseJson);
        let stockResponses = responseJson.data.map((stock) => new Stock(stock.n, stock.s));
        return stockResponses;
    }

    async getStockDetails(stock) {
        try {
            console.log(`logged details 1: ${stock.getSymbol()}`);
            const stockUrl = `${BASE_URL}/stocks/${stock.getSymbol()}`;
            // const response = await fetch(stockUrl, {
            //     mode: 'no-cors'
            //   });
            const response = await axios.get(stockUrl);
            
            //const html = await response.text();
            //console.log(`response text: ${html}`);
            //const $ = cheerio.load(html);
            const $ = cheerio.load(response.data)

            //$('tr').find((tr) => tr.html().cont)'
            console.log("logged details:");
            console.log($('tr').text());
            //console.log($('tr'));
            return {};

        } catch (ex) {
            console.log("logged details error");
            console.log(ex.message);
            return null;
        }
    }
}

export default StockAnalysisAPI;