import { legacy_createStore } from 'redux';

// Define the initial state
const initialState = {
  user: null,
};

// Define action types
const SET_USER = 'SET_USER';

// Create a reducer function
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

// Create a Redux store
const store = legacy_createStore(userReducer);

export default store;
