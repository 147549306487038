import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Parse from 'parse';
import { Provider } from 'react-redux';
import store from './data/store';

// Initialize Parse
Parse.initialize("PikN40NnoRECPZQC2s6qircUMAXU96KGzrnjfoHt", "7kce6l6afQ0OZ6w7MUn8EAIPEL6oyGcArRjPIQ1v", "7WsXqWEoCxFP7NtRm22z4tY8SO1J0O5uwuDPT2td"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.Cloud.useMasterKey = true;
Parse.serverURL = "https://parseapi.back4app.com/";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
