class StockDetails {
    constructor(stock, 
                sector, 
                industry,
                marketCap,
                pps,
                pps1YAgo,
                performance,
                rating) {
      this.stock = stock;
      this.sector = sector;
      this.industry = industry;
      this.marketCap = marketCap;
      this.pps = pps;
      this.pps1YAgo = pps1YAgo;
      this.performance = performance;
      this.rating = rating;
    }

    getId() {
        if(this.stock)
            return this.stock.id;
        else
            return "";
    }
  
    getName() {
        if(this.stock)
            return this.stock.name;
        else
            return "";
    }

    getSymbol() {
        if(this.stock)
            return this.stock.symbol;
        else
            return "";
    }

    getSector() {
        return this.sector;
    }

    getIndustry() {
        return this.industry;
    }

    getMarketCap() {
        return this.marketCap;
    }

    getPPS() {
        return this.pps;
    }

    getPPS1YAgo() {
        return this.pps1YAgo;
    }

    getPerformance() {
        return this.performance;
    }

    getStokilatorRanking() {
        return this.rating;
    }
  }

  export default StockDetails;