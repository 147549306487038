import '../App.css';
import stock_logo from '../logo.jpg';
import stock_slogan from '../logo_slogan.jpg';
import Parse from 'parse';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../data/actions';

import { Form, Button } from 'react-bootstrap';

function LoginPage({ onLoginPass, onLoginFail}) {

    const dispatch = useDispatch();

    const submitLogin = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        console.log(`User: ${form.username.value}, Password: ${form.password.value}`);
        Parse.User
        .logIn(form.username.value, form.password.value).then(function(user) {
            console.log('User logged in successful with name: ' + user.get("username") + ' and email: ' + user.get("email"));
            dispatch(setUser(user));
            onLoginPass();
        }).catch(function(error){
            console.log("Error: " + error.code + " " + error.message);
            onLoginFail();
        });
    };

    return (
         <div className="App-header" >
            <Form onSubmit={submitLogin}>
                <Form.Group className="mb-3">
                    <img src={stock_logo}  className="App-logo" alt="logo" />
                </Form.Group>

                <Form.Group className="mb-3">
                    <img src={stock_slogan}  className="App-logo" alt="logo" />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>A NEW VERSION COMING SOON! </Form.Label>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Sign In</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control required placeholder="Username" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control required type="password" placeholder="Password" />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>

         </div>
    );
  }
  
  export default LoginPage;