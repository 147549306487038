
class Portfolio {
  constructor(name, id) {
    this.id = id;
    this.name = name;
  }

  getId() {
    return this.id;
  }

  getName() {
      return this.name;
  }
}

export default Portfolio;
